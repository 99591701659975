import getActionElement from '../helpers/getActionElement';
import isActionTriggerValid from '../helpers/isActionTriggerValid';
import getTargetElement from '../helpers/getTargetElement';
import { isSafari } from '../helpers/browserType';

const EXAMPLE_SECTION_CLASS_NAME = 'example-thumbnails__section';
const pageListOffset = 45;
let sectionAreas = [];

function setPageListEventListener() {
  getActionElement('page-list').addEventListener('click', handleListScrollToSection);
  window.addEventListener('scroll', handlePageListSelectByScroll);
  window.addEventListener('resize', handleWindowResize);
}

function handleWindowResize(e) {
  getSectionAreas(e);
  setSectionAreasCoordsCorrections(e);
  handlePageListSelectByScroll(e)
}

function getSectionPosition(section) {
  return section.offsetTop - pageListOffset
}

function handleListScrollToSection(e) {
  if(isActionTriggerValid(e, 'scroll-to-category')) {
    e.preventDefault();

    const section = getTargetElement(e.target);
    const sectionEl = document.getElementById(section);

    if (isSafari) {
      const scrollBy = (elAmt) => {
        window.scrollBy(0, elAmt);
      }
      const elTop = sectionEl.getBoundingClientRect().top;
      const elAmt = elTop / 100;
      const time = 300;
      let curTime = 0;

      while (curTime <= time) {
        window.setTimeout(scrollBy, curTime, elAmt);
        curTime += time / 100;
      }
    } else {
      window.scrollTo({
        top: getSectionPosition(sectionEl),
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}

function getSectionAreas() {
  const thumbnailSections = document.querySelectorAll('.example-thumbnails__section');

  thumbnailSections.forEach((section) => {
    sectionAreas.push({
      name: section.id,
      start: section.offsetTop,
      end: section.offsetTop + section.offsetHeight,
    });
  });
}

function setSectionAreasCoordsCorrections() {
  const FIRST_TOP_OFFSET = parseInt(window.getComputedStyle(document.querySelector('.page-list')).getPropertyValue('top'));
  const SECTIONS_SPACING = sectionAreas[1].start - sectionAreas[0].end;
  const OFFSET_PROPORTION = 0.15;
  let MIDDLE_TOP_OFFSET = window.innerHeight * OFFSET_PROPORTION;

  sectionAreas.forEach((area, index) => {
    area.start = index === 0 ? area.start - FIRST_TOP_OFFSET : area.start - MIDDLE_TOP_OFFSET;
    area.end = index === (sectionAreas.length - 1)
      ? area.end + FIRST_TOP_OFFSET + MIDDLE_TOP_OFFSET
      : (area.end - MIDDLE_TOP_OFFSET) + SECTIONS_SPACING;
  });
}

function handlePageListSelectByScroll(e) {
  const currentScrollPosition = window.scrollY;

  sectionAreas.forEach((area) => {
    const currentCategory = document.querySelector(`.page-list__section-heading [data-category=${area.name}]`);
    const ACTIVE_CLASS_NAME = 'page-list__active-category';

    if(currentScrollPosition >= area.start && currentScrollPosition <= area.end) {
      currentCategory.classList.add(ACTIVE_CLASS_NAME);
    } else {
      if (currentCategory.classList.contains(ACTIVE_CLASS_NAME)) {
        currentCategory.classList.remove(ACTIVE_CLASS_NAME);
      }
    }
  });
}

function setPositionByHref() {
  const sectionId = getTargetElement(window.location.href, false);

  if(sectionId) {
    const section = document.getElementById(sectionId);

    window.scrollTo({
      top: getSectionPosition(section),
      left: 0,
    });
  }
}

export function initializePageList() {
  if (!document.querySelector(`.${EXAMPLE_SECTION_CLASS_NAME}`)) {
    return false;
  }

  setPositionByHref();
  getSectionAreas();
  setSectionAreasCoordsCorrections();
  handlePageListSelectByScroll();
  setPageListEventListener();
}
