const intercomBtn = document.querySelector('[data-action-chat]')

function disableChatButton() {
  intercomBtn && intercomBtn.addEventListener('click', function(e) {
    // If intercom is initiated disable the link to prevent the opening link. Opening chat is handled via intercom
    if (e && window && window.Intercom) {
      e.preventDefault();
    }
  })
};

export function initializeIntercomBtn() {
  disableChatButton();
}
