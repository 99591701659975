import getActionElement from "../helpers/getActionElement";
import {func} from "prop-types";

const REQUEST_ACCESS_FORM = 'request-access-form';
const REQUEST_ACCESS_FIELD = 'request-action-field';
const REQUEST_ACCESS_BTN = 'request-action-btn';
const TYPEFORM_TRIGGER = 'typeform-trigger';
const FIELDS_CONTAINER = 'fields-container';
const SUCCESS_CONTAINER = 'success-container';
const ERROR_MSG_CLASS_NAME = 'field-error';
const FIELD_ERROR_CLASS_NAME = 'input-field--is-error';

function getIntegrationType() {
  if (window.location.href.includes('git-integration')) {
    return 'git';
  }

  if (window.location.href.includes('storybook-integration')) {
    return 'storybook';
  }

  return 'merge';
}

const getMixPanel = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (window.mixpanel) {
        clearInterval(interval);
        return resolve(window.mixpanel);
      }
    }, 1500);
  });
}

function handleMergeRequestTracking(email) {
  const integrationType = getIntegrationType();


  getMixPanel().then((mixpanel) => {
    mixpanel.track('merge_access_requested', {
      request_email: email,
      action_location: 'web',
      merge_access_requested_integration_type: integrationType,
    });
  })


  window.gtmTrack({
    event: 'merge_access_requested',
    merge_access_requested_integration_type: integrationType,
  });
}

function setActionBtnListeners() {
  getActionElement(REQUEST_ACCESS_FORM, true).forEach((el) => {
    el.addEventListener('submit', handleSubmit);
  });
}

function handleSubmit(e) {
  e.preventDefault();

  const form = this;
  const input = form.querySelector(`[data-action=${REQUEST_ACCESS_FIELD}]`);

  validateField(form, input);

  if (this.dataset.valid === 'true') {
    handleMergeRequestTracking(input.value);
    handleSuccess(form);
    typeformCall(form, input.value);
  }
}

function validateField(form, field) {
  const formEl = form;
  const fieldEl = field;
  const value = fieldEl.value;

  if (value === '') {
    handleValidationOnForm(formEl, false);
    handleErrorOnField(fieldEl, true);
    handleFormMsg(fieldEl, 'Please type your email.');
  } else if (!/\S+@\S+\.\S+/.test(value)) {
    handleValidationOnForm(formEl, false);
    handleErrorOnField(fieldEl, true);
    handleFormMsg(fieldEl, 'It`s not a valid e-mail address.');
  } else {
    handleValidationOnForm(formEl, true);
    handleErrorOnField(fieldEl, false);
    handleFormMsg(fieldEl);
  }
}

function handleValidationOnForm(form, valid) {
  form.dataset.valid = `${valid}`;
}

function handleErrorOnField(field, error) {
  if (error) {
    field.classList.add(FIELD_ERROR_CLASS_NAME);
  } else {
    field.classList.remove(FIELD_ERROR_CLASS_NAME);
  }
}

function handleSuccess(form) {
  form.querySelector(`[data-action=${REQUEST_ACCESS_BTN}]`).disabled = true;
  setTimeout(() => addSuccessMessage(form), 1000);
}

function addSuccessMessage(form) {
  const container = form.querySelector(`[data-action=${SUCCESS_CONTAINER}]`);

  if (container && hasWaitListForm()) {
    const successMsg = document.createElement('span');
    successMsg.classList.add('form-hint', 'icon--wait-list-check', 'wait-list-form-success');
    successMsg.textContent = 'Thank you for signing up! We\'ve sent confirmation to your email';
    container.innerHTML = '';
    container.appendChild(successMsg);
  } else if (container) {
    const successMsg = document.createElement('span');
    successMsg.classList.add('form-success', 'icon--check');
    successMsg.textContent = 'Thank you for submitting the form!';
    container.innerHTML = '';
    container.appendChild(successMsg);
  }
}

function handleFormMsg(form, msg) {
  const errorContainer = form.form.querySelector(`[data-action=${FIELDS_CONTAINER}]`);
  let msgEl = errorContainer.querySelector(`.${ERROR_MSG_CLASS_NAME}`);

  if (msg) {
    if (!msgEl) {
      msgEl = document.createElement('span');
      msgEl.classList.add(ERROR_MSG_CLASS_NAME);
      msgEl.textContent = msg;
      errorContainer.appendChild(msgEl);
    } else {
      msgEl.textContent = msg;
    }
  } else if (!msg && msgEl) {
    errorContainer.removeChild(msgEl);
  }
}

function hasWaitListForm() {
  return Boolean(document.getElementById('wait-list-form'));
}

function typeformCall(form, email) {
  const typeformTrigger = form.querySelector(`[data-action=${TYPEFORM_TRIGGER}]`);
  const href = typeformTrigger.href;

  typeformTrigger.href = `${href}?email=${email}`;
  typeformTrigger.click();
}

export function initializeRequestAccessForm() {
  if (!getActionElement(REQUEST_ACCESS_FORM)) {
    return false;
  }

  setActionBtnListeners();
}
