import { initializePageList } from './components/pageList';
import { initializeViewportPlay } from './components/viewportPlay';
import { initializeIntercomBtn } from './components/intercomButton';
import { initializeEnterpriseCarousel } from './components/enterpriseCarousel';
import { initializeRequestAccessForm } from './components/requestAccess';
import { initializeInteractiveButtons } from './components/interactiveButton';

function onLoad() {
  initializePageList();
  initializeViewportPlay();
  initializeIntercomBtn();
  initializeEnterpriseCarousel();
  initializeRequestAccessForm();
  initializeInteractiveButtons();
}

window.addEventListener('load', onLoad);
