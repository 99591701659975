import getActionElement from '../helpers/getActionElement';

const VIEWPORT_PLAY_HANDLER = 'viewport-play';
let windowWidth;
let windowHeight;

function storeWindowSize() {
  windowWidth = window.innerWidth || document.documentElement.clientWidth;
  windowHeight = window.innerHeight || document.documentElement.clientHeight;
}

function isElInViewport(el) {
  const elBounding = el.getBoundingClientRect();
  const elWidth = elBounding.width;
  const elHeight = elBounding.height;

  return (
    elBounding.top >= -elHeight &&
    elBounding.left >= -elWidth &&
    elBounding.bottom <= windowHeight+elHeight &&
    elBounding.right <= windowWidth+elWidth
  );
}

function playElementsOnViewport() {
  const elements = getActionElement(VIEWPORT_PLAY_HANDLER, true);

  elements.forEach((el) => {
    if (isElInViewport(el)) {
      el.play();
    } else {
      el.pause();
    }
  });
}

function setViewportPlayEventListeners() {
  document.addEventListener('resize', storeWindowSize);
  document.addEventListener('scroll', playElementsOnViewport);
}

export function initializeViewportPlay() {
  if (!getActionElement(VIEWPORT_PLAY_HANDLER)) {
    return false;
  }

  storeWindowSize();
  playElementsOnViewport();
  setViewportPlayEventListeners();
}
