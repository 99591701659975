const SECTION_CLASS_NAME = 'interactive_buttons__container';

function updateStep(stepNumber) {
  document.querySelectorAll('.interactive-button').forEach((button) => {
    button.classList.remove('active');
    button.classList.add('not-active');
  });

  document.querySelectorAll('.mobile-step').forEach((step) => {
    step.classList.add('not-active');
  });
  document.getElementById(`mobilestep${stepNumber}`).classList.remove('not-active');
  document.getElementById(`step${stepNumber}`).classList.add('active');
  document.getElementById(`step${stepNumber}`).classList.remove('not-active');

  document.querySelectorAll('.interactive_buttons__container-bottom > div').forEach((content) => {
    // eslint-disable-next-line no-param-reassign
    content.style.display = 'none';
  });
  document.getElementById(`description${stepNumber}`).style.display = 'grid';
}

function progressBarAnimation(stepNumber) {
  const progressBar = document.getElementById(`progress${stepNumber}`);
  progressBar.style.width = '0'; // Ensure the width is reset
  setTimeout(() => {
    progressBar.style.transition = 'width 10s linear';
    progressBar.style.width = '100%';
  }, 50);
}

const classObserver = new MutationObserver((mutations) => {
  mutations.forEach((mu) => {
    if (mu.type !== 'attributes' && mu.attributeName !== 'class') return;
    if (mu.target.className.includes('not-active')) return;
    const ACTIVE_STEP = mu.target.id.charAt(mu.target.id.length - 1);
    progressBarAnimation(ACTIVE_STEP);
  });
});

function setProgressListeners() {
  if (isMobileDeviceUsed()) return;

  progressBarAnimation(1);
  const pills = document.querySelectorAll('.interactive-button');
  pills.forEach((el) => classObserver.observe(el, { attributes: true }));

  const progressBars = document.querySelectorAll('.full-progress-bar');
  progressBars.forEach((el) => el.addEventListener('transitionend', (event) => {
    if (event.propertyName === 'width') {
      const ACTIVE_STEP = el.id.charAt(el.id.length - 1);
      updateStep((ACTIVE_STEP % 3) + 1);
    }
  }));
}

function isMobileDeviceUsed() {
  return /iPhone|iPad|iPod|Android|webOS|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent);
}

export function initializeInteractiveButtons() {
  if (!document.querySelector(`.${SECTION_CLASS_NAME}`)) {
    return false;
  }
  setProgressListeners();
}
