export function initializeEnterpriseCarousel() {
  document.querySelectorAll('.carousel-left ul').forEach((carousel) => {
    carousel.addEventListener('click', (e) => {
      if (e.target.tagName !== 'DIV') {
        return;
      }

      e.preventDefault();
      const clickIndex = Array.prototype.indexOf.call(carousel.children, e.target.parentElement);

      Array.prototype.forEach.call(carousel.children, ((li, liIndex) => {
        if (liIndex === clickIndex) {
          li.classList.add('enterprise-carousel__item--active');
        } else {
          li.classList.remove('enterprise-carousel__item--active');
        }
      }));
    });
  });
}