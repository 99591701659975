export default function getTargetElement(e, isElement = true) {
  const href = isElement ? e.getAttribute('href') : e;
  const hashIndex = href.indexOf('#');

  if(hashIndex === -1) {
    return null;
  }

  return href.substr(href.indexOf('#') + 1);
}
